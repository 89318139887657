import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import Container from '../components/Container'
import Layout from '../components/Layout'
import MediaObject from '../components/MediaObject'
import Section from '../components/Section'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

const PostHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;

  .label {
    margin-bottom: 0;
  }

  .headline {
    margin-top: 14px;
    margin-bottom: 20px;
  }

  .detail {
    margin-bottom: 25px;
  }

  .gatsby-img-container,
  .gatsby-image-wrapper {
    width: 100%;
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    margin-bottom: 40px;

    .headline {
      margin-top: 13px;
      margin-bottom: 22px;
    }

    .detail {
      margin-bottom: 40px;
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.overMedium}) {
    .gatsby-img-container {
      padding-left: ${props => props.theme.spacing.siteXPadding};
      padding-right: ${props => props.theme.spacing.siteXPadding};
    }
  }
`

const SectionTitle = styled.h4`
  margin-top: 40px;
`

const ContainRelatedPosts = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`

const PostTemplate = ({ data, pageContext, ...props }) => {
  const {
    title,
    slug,
    heroImage,
    body,
    publishDate,
    category,
  } = data.contentfulPost
  const postNode = data.contentfulPost

  const relatedPosts = data.allContentfulPost.edges

  const previous = pageContext.prev
  const next = pageContext.next

  return (
    <Layout pageClass="page--post" noHeroSection="true">
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO pagePath={slug} postNode={postNode} postSEO />

      <Container isPost="true">
        <Section>
          <PostHeader>
            <Container wrap="true">
              <p className="label">{category.title}</p>
              <h1 className="headline--large">{title}</h1>
              <p className="detail">{publishDate}</p>
            </Container>
            <div className="gatsby-img-container">
              <Img fluid={heroImage.fluid} />
            </div>
          </PostHeader>
          <Container wrap="true">
            <div
              className="post-body"
              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.html,
              }}
            />
            <SectionTitle className="section-title">Related Posts</SectionTitle>
            <ContainRelatedPosts>
              {relatedPosts.map(({ node: post }) => (
                <MediaObject
                  key={post.slug}
                  title={post.title}
                  category={post.category.title}
                  publishDate={post.publishDate}
                  slug={`news/${post.slug}`}
                  heroImage={post.heroImage}
                />
              ))}
            </ContainRelatedPosts>
          </Container>
        </Section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $catId: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      category {
        title
        id
        slug
      }
      heroImage {
        title
        fluid(quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
    }
    allContentfulPost(
      filter: { category: { id: { eq: $catId } }, slug: { ne: $slug } }
      sort: { fields: [publishDate], order: [DESC] }
      limit: 3
    ) {
      edges {
        node {
          slug
          title
          heroImage {
            title
            fluid(quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          category {
            title
          }
          publishDate(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`

export default PostTemplate
